import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CheckboxInput from '../components/CheckboxInput';
import Input from '../components/inputForm';

import credential from '../assets/credential.png';
import playerExample from '../assets/player-example.png';
import ImageSimple from '../components/ImageSimple';
import ImageSimpleForm from '../components/ImageSimpleForm';

import {
	validateName,
	validateNumber,
	validateEmail,
} from '../utils/validations';
import { getDay, getMonth, getYear } from '../utils/getDetailsDate';
import { getExtensionFile } from '../utils/getExtensionFile';
import { getBase64 } from '../utils/convertToBase64';
import { validateDate } from '../utils/validateDate';
import { convertDateToUTC } from '../utils/getDateUTC';
import { addPlayer, updatePlayer } from '../lib/ApiEcyd';
import { messageError } from '../lib/AlertsUtils';
import { ErrorMessages } from '../constants/ErrorMessages';
import useusePointInvalidMessage from '../hooks/usePointMessage';
import { useCup } from '../context/cup-context';
import { CurrentYear } from '../constants/Numbers';
import RadioInput from '../components/RadioInput';

const keysInputText = [
	'name',
	'lastName',
	'secondLastName',
	'curp',
	'shirt',
	'phoneNumber',
	'email',
	'schoolName',
	'contactName',
	'contactPhoneNumber',
];
const contactNumber = process.env.REACT_APP_CONTACT_NUMBER;

const PlayerForm = ({ account, cup, player, method }) => {
	const navigate = useNavigate();
	const { cupPermalink } = useParams();
	const formRef = useRef();
	const [validBirthDateRef, toggleValidBirthDate] = useusePointInvalidMessage(
		'my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center'
	);
	const [validDateRef, toggleValidDateRef] = useusePointInvalidMessage(
		'my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center'
	);
	const { getCupColor } = useCup();
	const sizes = ['XS', 'S', 'M', 'L', 'XL'];

	const [credentialPreview, setCredentialPreview] = useState(
		player?.idUrl || credential
	);

	const categories =
		cup?.season?.categories ||
		JSON.parse(window.localStorage.getItem('categories'));

	const savePlayer = async () => {
		const formData = new FormData();
		for (let key of keysInputText) {
			const value = formRef.current[key].value;
			!!value && formData.append(key, formRef.current[key].value);
		}

		const valueReinforcements =
			formRef.current['reinforcements'].checked || false;
		formData.append('reinforcements', valueReinforcements);

		const sizeCategoryTop = formRef.current['isChildTop'].checked || false;
		formData.append('isChildTop', sizeCategoryTop);
		const sizeTop = formRef.current['sizeTop'].value || false;
		if (!sizeTop) {
			alert('select sizeTop please');
			return;
		}
		formData.append('sizeTop', sizeTop);

		const sizeCategoryBottom =
			formRef.current['isChildBottom'].checked || false;
		formData.append('isChildBottom', sizeCategoryBottom);
		const sizeBottom = formRef.current['sizeBottom'].value || false;
		if (!sizeBottom) {
			alert('select sizeBottom please');
			return;
		}
		formData.append('sizeBottom', sizeBottom);

		if (cup?.season?.requiredConstancy) {
			const value = formRef.current['idBase64'].files[0];
			if (value) {
				const idBase64 = await getBase64(value);
				formData.append('idBase64', idBase64);
				formData.append(
					'idExt',
					getExtensionFile(formRef.current['idBase64'].value)
				);
			}
		}
		const value = formRef.current['photoBase64'].files[0];
		if (value) {
			const photoBase64 = await getBase64(value);
			formData.append('photoBase64', photoBase64);
			formData.append(
				'photoExt',
				getExtensionFile(formRef.current['photoBase64'].value)
			);
		}

		if (
			!!formRef.current.year.value &&
			!!formRef.current.year.value &&
			!!formRef.current.year.value
		) {
			const birthDate = convertDateToUTC(
				formRef.current.year.value,
				formRef.current.month.value,
				formRef.current.day.value
			);
			formData.append('BirthDate', birthDate);

			//console.log("BirthDate", birthDate);
			const {
				ageStartAt,
				ageEndAt,
				reinforcementsAgeStartAt,
				reinforcementsAgeEndAt,
			} = account.category;
			if (!valueReinforcements) {
				if (birthDate < ageStartAt || birthDate > ageEndAt) {
					toggleValidBirthDate(false);
					return;
				} else {
					toggleValidBirthDate(true);
				}
			} else {
				if (
					birthDate < reinforcementsAgeStartAt ||
					birthDate > reinforcementsAgeEndAt
				) {
					toggleValidBirthDate(false);
					return;
				} else {
					toggleValidBirthDate(true);
				}
			}
		}

		// curp
		const curp = formRef.current['curpBase64'].files[0];
		if (curp) {
			const curpBase64 = await getBase64(curp);
			formData.append('curpBase64', curpBase64);
			let fileName = curp.value || curp.name;
			if (fileName) {
				formData.append('curpExt', getExtensionFile(fileName));
			} else {
				formData.append('curpExt', 'pdf');
			}
		}
		// passport
		const passport = formRef.current['passportBase64'].files[0];
		if (passport) {
			const passportBase64 = await getBase64(passport);
			formData.append('passportBase64', passportBase64);
			let fileName = passport.value || passport.name;
			if (fileName) {
				formData.append('passportExt', getExtensionFile(fileName));
			}
		}

		console.log(formData);

		if (method === 'POST') {
			addPlayer(formData, cup?.contact?.phoneNumber || contactNumber).then(
				(data) => {
					window.sessionStorage.clear();
					if (data?.statusText === 'OK' || data?.status === 200) {
						navigate(`/${cupPermalink}/account`);
					}
				}
			);
		} else {
			updatePlayer(
				player.uuid,
				formData,
				cup?.contact?.phoneNumber || contactNumber
			).then((data) => {
				window.sessionStorage.clear();
				if (data?.statusText === 'Created' || data?.status === 201) {
					navigate(`/${cupPermalink}/account`);
				}
			});
		}
	};

	const OnSubmit = async (e) => {
		e.preventDefault();
		if (
			!validateDate(
				e.target.year.value,
				e.target.month.value,
				e.target.day.value
			)
		) {
			toggleValidDateRef(false);
			return;
		} else {
			toggleValidDateRef(true);
		}

		const birthDate = convertDateToUTC(
			e.target.year.value,
			e.target.month.value,
			e.target.day.value
		);

		const {
			ageStartAt,
			ageEndAt,
			reinforcementsAgeStartAt,
			reinforcementsAgeEndAt,
		} = account.category;

		if (!e.target.reinforcements.checked) {
			if (birthDate < ageStartAt || birthDate > ageEndAt) {
				toggleValidBirthDate(false);
				return;
			} else {
				toggleValidBirthDate(true);
			}
		} else {
			if (
				birthDate < reinforcementsAgeStartAt ||
				birthDate > reinforcementsAgeEndAt
			) {
				toggleValidBirthDate(false);
				return;
			} else {
				toggleValidBirthDate(true);
			}
		}

		const formData = new FormData();
		formData.append('name', e.target.name.value);
		formData.append('lastName', e.target.lastName.value);
		formData.append('secondLastName', e.target.secondLastName.value);
		formData.append('curp', e.target.curp.value);
		formData.append('shirt', Number(e.target.shirt.value));
		formData.append('phoneNumber', e.target.phoneNumber.value);
		formData.append('email', e.target.email.value);
		formData.append('schoolName', e.target.schoolName.value);
		formData.append('reinforcements', e.target.reinforcements.checked || false);

		if (cup?.season?.requiredConstancy) {
			if (!player || e.target.idBase64.files[0]) {
				const idBase64 = await getBase64(e.target.idBase64.files[0]);
				formData.append('idBase64', idBase64);
				formData.append('idExt', getExtensionFile(e.target.idBase64.value));
			}
		}
		if (!player || !!e.target.photoBase64.files[0]) {
			const photoBase64 = await getBase64(e.target.photoBase64.files[0]);
			formData.append('photoBase64', photoBase64);
			formData.append('photoExt', getExtensionFile(e.target.photoBase64.value));
		}
		formData.append('contactName', e.target.contactName.value);
		formData.append('contactPhoneNumber', e.target.contactPhoneNumber.value);
		formData.append('BirthDate', birthDate);

		const sizeCategoryTop = formRef.current['isChildTop'].checked || false;
		formData.append('isChildTop', sizeCategoryTop);
		const sizeTop = formRef.current['sizeTop'].value || false;
		if (!sizeTop) {
			alert('select sizeTop please');
			return;
		}
		formData.append('sizeTop', sizeTop);

		const sizeCategoryBottom =
			formRef.current['isChildBottom'].checked || false;
		formData.append('isChildBottom', sizeCategoryBottom);
		const sizeBottom = formRef.current['sizeBottom'].value || false;
		if (!sizeBottom) {
			alert('select sizeBottom please');
			return;
		}
		formData.append('sizeBottom', sizeBottom);

		// curp
		if (!player || !!e.target.curpBase64.files[0]) {
			const curpBase64 = await getBase64(e.target.curpBase64.files[0]);
			formData.append('curpBase64', curpBase64);
			formData.append('curpExt', getExtensionFile(e.target.curpBase64.value));
		}
		// passport
		if (!player || !!e.target.passportBase64.files[0]) {
			const passportBase64 = await getBase64(e.target.passportBase64.files[0]);
			formData.append('passportBase64', passportBase64);
			formData.append(
				'passportExt',
				getExtensionFile(e.target.passportBase64.value)
			);
		}

		console.log(formData);

		if (method === 'POST') {
			addPlayer(formData, cup?.contact?.phoneNumber || contactNumber).then(
				(data) => {
					window.sessionStorage.clear();
					if (data?.statusText === 'OK' || data?.status === 200) {
						navigate(`/${cupPermalink}/account`);
					}
				}
			);
		} else {
			updatePlayer(
				player.uuid,
				formData,
				cup?.contact?.phoneNumber || contactNumber
			).then((data) => {
				window.sessionStorage.clear();
				if (data?.statusText === 'Created' || data?.status === 201) {
					navigate(`/${cupPermalink}/account`);
				}
			});
		}
	};

	return (
		<div className="mt-10 md:mt-5 md:col-span-2">
			<form name="player-form" ref={formRef} onSubmit={OnSubmit}>
				<div className="shadow overflow-hidden sm:rounded-md">
					<div className="px-4 py-5 bg-white sm:p-6">
						<h3 className="text-3xl">
							〉Información del equipo
							<hr />
						</h3>
						<div className="grid grid-cols-6 gap-6 mt-4 mb-14">
							<div className="col-span-6 sm:col-span-3 lg:col-span-2">
								<label className="block text-title" htmlFor="inline-full-name">
									Nombre del equipo
								</label>
								<p
									className="block tracking-wide text-gray-500 text-md font-bold mt-4"
									htmlFor="grid-first-name"
								>
									<code>{account.teamName}</code>
								</p>
							</div>
							<div className="col-span-6 sm:col-span-3 lg:col-span-2">
								<label className="block text-title" htmlFor="inline-full-name">
									Categoría
								</label>
								<p
									className="block tracking-wide text-gray-500 text-md font-bold mt-4"
									htmlFor="grid-first-name"
								>
									<code>
										{
											categories.find(
												(category) => category.uuid === account.category.uuid
											)?.name
										}
									</code>
								</p>
							</div>

							<div className="my-5 col-span-6 sm:col-span-6 lg:col-span-3">
								<ImageSimple
									id="photoBase64"
									imgId="playerPhoto"
									name="imageFile"
									src={player?.photoUrl}
									alt="image-example"
									title="Fotografía"
									text="* La foto debe de ser con fondo blanco, en formato jpg o png, vertical y máximo 6Mb"
									withFile={true}
									message="No PDF"
									required={player?.photoUrl ? '' : 'required'}
								/>
							</div>

							<div className=" my-5 col-span-6 sm:col-span-6 lg:col-span-3">
								<ImageSimple
									src={playerExample}
									alt="image-example"
									text="* Ejemplo de cómo debe verse la fotografía"
									required="required"
								/>
							</div>

							<CheckboxInput
								id="reinforcements"
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
								label="Soy un refuerzo"
								name="reinforcements"
								description=""
								initialValue={player?.reinforcements}
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-2"
								label="Nombre(s) completo"
								placeholder="Nombre(s)"
								name="name"
								id="name"
								initialValue={player?.name}
								validation={validateName}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Apellido Paterno"
								placeholder="Apellido Paterno"
								name="lastName"
								id="lastName"
								type="text"
								initialValue={player?.lastName}
								validation={validateName}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Apellido Materno"
								placeholder="Apellido Materno"
								name="secondLastName"
								id="secondLastName"
								type="text"
								initialValue={player?.secondLastName}
								validation={validateName}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-2"
								label="Día de nacimiento"
								placeholder="00"
								id="day"
								name="day"
								initialValue={getDay(player?.birthDate)}
								validation={validateNumber}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Mes de nacimiento"
								placeholder="00"
								id="month"
								name="month"
								type="text"
								initialValue={getMonth(player?.birthDate)}
								validation={validateNumber}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Año de nacimiento"
								placeholder="2000"
								id="year"
								name="year"
								type="text"
								initialValue={getYear(player?.birthDate)}
								validation={validateNumber}
								required="required"
							/>

							<div
								ref={validDateRef}
								className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center hidden"
							>
								<p className="text-primary text-red-500">
									Inserta una fecha válida.
								</p>
							</div>

							<div
								ref={validBirthDateRef}
								className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6 hidden"
							>
								<p className="text-primary text-red-500">
									Tu jugador no puede inscribirse en esta categoría. Si deseas
									cambiar de categoría a todo tu equipo favor de comunicarte al{' '}
									<span className="underline">
										{cup?.contact?.phoneNumber || contactNumber}
									</span>
									.
								</p>
							</div>
							<Input
								divClassName="my-5 col-span-6 sm:col-span-6 lg:col-span-2"
								label="Número de jugador (playera)"
								placeholder="Número de jugador "
								id="shirt"
								name="shirt"
								initialValue={player?.shirt}
								validation={validateNumber}
								required="required"
							/>

							<Input
								divClassName="my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Teléfono"
								placeholder="Teléfono"
								id="phoneNumber"
								name="phoneNumber"
								type="text"
								initialValue={player?.phoneNumber}
								validation={validateNumber}
								required="required"
							/>

							<Input
								divClassName="my-5 col-span-6 sm:col-span-3 lg:col-span-2"
								label="Correo electrónico"
								placeholder="Correo electrónico"
								id="email"
								name="email"
								type="email"
								initialValue={player?.email}
								validation={validateEmail}
								required="required"
							/>

							<Input
								divClassName="col-span-6 sm:col-span-3 lg:col-span-3"
								label="CURP"
								placeholder="Ingresa tu CURP"
								id="curp"
								name="curp"
								type="text"
								initialValue={player?.curp}
								//validation={validateCURP}
								required="required"
							/>

							<div className="col-span-4 sm:col-span-4 lg:col-span-4">
								{player?.curpUrl ? 
									<div className="col-span-4 sm:col-span-4 lg:col-span-42">
										<a 
											href={player.curpUrl} 
											class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
												{player.curpUrl}</a>
									</div> 
									: ''
								}
								<div className="col-span-4 sm:col-span-4 lg:col-span-4">
									<label className="block text-title">
										<p><a
											href="https://www.gob.mx/curp/"
											class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
											target="_blank"
											rel="noreferrer"
										>
											Genera tu CURP
										</a></p>
										<p>Adjunta el archivo PDF</p>
									</label>
								</div>
								<Input
									divClassName="col-span-6 sm:col-span-6 lg:col-span-3"
									id="curpBase64"
									name="curpBase64"
									type="file"
									setParentState={(e) => {
										const ext = getExtensionFile(e.target.value);
										if (!['pdf'].includes(ext)) {
											const { title, description } = ErrorMessages.pdfFormat;
											messageError(title, description);
											e.target.value = null;
											e.target.curpBase64 = null;
											return;
										}
										const mb = 1024;
										if (e.target.files[0].size / mb > mb * 6) {
											const { title, description } = ErrorMessages.fileSize;
											messageError(title, description);
											e.target.value = null;
											e.target.curpBase64 = null;
											return;
										}
									}}
								/>
								<div className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-4 bg-gray-100 px-3 py-1 rounded-sm mr-2">
									<p className="w-full text-primary">
										Subirlo en <strong>PDF</strong>
									</p>
									<ul className="px-5 py-2 text-primary">
										<li>✖ No Pasaporte</li>
										<li>✖ No Actas de Nacimiento</li>
										<li>✖ No Credenciales</li>
									</ul>
								</div>
							</div>
							<div></div>

							<PlayerSizeSelector sizes={sizes} permalink={cup?.permalink} player={player} />

							<div className="col-span-4 sm:col-span-4 lg:col-span-4">
								<label className="block text-title">
									<a
										href="https://www.nike.com/size-fit/kids-clothing"
										class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
										target="_blank"
										rel="noreferrer"
									>
										Guía de Tallas
									</a>
								</label>
							</div>

							<div className="col-span-4 sm:col-span-4 lg:col-span-4">
								{player?.passportUrl ? 
									<div className="col-span-4 sm:col-span-4 lg:col-span-42">
										<a 
											href={player.passportUrl} 
											class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
												{player.passportUrl}</a>
									</div> 
									: ''
								}
								<Input
									label="Pasaporte"
									id="passportBase64"
									name="passportBase64"
									type="file"
									setParentState={(e) => {
										const mb = 1024;
										if (e.target.files[0].size / mb > mb * 6) {
											const { title, description } = ErrorMessages.fileSize;
											messageError(title, description);
											e.target.value = null;
											e.target.passportBase64 = null;
											return;
										}
										const ext = getExtensionFile(e.target.value);
										if (!['pdf', 'jpeg', 'jpg', 'png', 'svg'].includes(ext)) {
											const { title, description } = ErrorMessages.passportFormat;
											messageError(title, description);
											e.target.value = null;
											e.target.passportBase64 = null;
											return;
										}
									}}
								/>
								<div className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-4 bg-gray-100 px-3 py-1 rounded-sm mr-2">
									<p className="w-full text-primary">
										Subirlo en <strong>PDF</strong> o <strong>JPG/JPEG</strong>
									</p>
									<ul className="px-5 py-2 text-primary">
										<li>✖ No CURP</li>
										<li>✖ No Actas de Nacimiento</li>
										<li>✖ No Credenciales</li>
									</ul>
								</div>
							</div>
						</div>

						<h3 className="text-3xl">
							〉Información escolar <hr />
						</h3>
						<div className="grid my-4">
							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-3"
								label="Escuela"
								placeholder="Escuela"
								id="schoolName"
								name="schoolName"
								type="text"
								initialValue={player?.schoolName}
								required="required"
							/>
						</div>
						{cup?.season?.requiredConstancy && (
							<div className="mb-20">
								<Input
									divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-3"
									label="Credencial o constancia académica"
									id="idBase64"
									name="idBase64"
									type="file"
									setParentState={(e) => {
										const ext = getExtensionFile(e.target.value);
										if (!['jpeg', 'png', 'jpg', 'svg'].includes(ext)) {
											const { title, description } =
												ErrorMessages.imageFormat;
											messageError(title, description);
											e.target.value = null;
											e.target.idBase64 = null;
											return;
										}
										const objectUrl = URL.createObjectURL(e.target.files[0]);
										setCredentialPreview(objectUrl);
									}}
									message="No PDF"
									required={player?.idUrl ? '' : 'required'}
								/>

								<div className="grid grid-cols-6">
									<div className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-4 bg-gray-100 px-3 py-1 rounded-sm mr-2">
										<p className="w-full text-primary">
											La credencial o constancia academica debe de tener los
											siguientes requisitos:
										</p>
										<div className="px-5 py-2 text-primary">
											<li>Nombre completo</li>
											<li>Fotografía</li>
											<li>Grado y Ciclo escolar</li>
											<li>
												No se aceptan CURP, pasaportes ni acta de nacimiento
											</li>
										</div>
										<p className="w-full text-primary">
											La constancia debe estar rotulada y con firma del
											director o coordinador académico
										</p>
									</div>

									<div className="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-2 text-title">
										<ImageSimpleForm
											src={credentialPreview}
											alt="credential"
											text={
												credentialPreview !== credential
													? ''
													: '* Ejemplo de credencial'
											}
											imgHeightClass="h-40"
											imgWidthClass="w-full"
										/>
									</div>
								</div>
							</div>
						)}
						<h3 className="text-3xl">
							〉Información de contacto <hr />
						</h3>
						<div className="grid grid-cols-6 gap-6 mt-4 mb-10">
							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-3"
								label="Nombre de contacto de emergencia"
								placeholder="Nombre de contacto de emergencia"
								id="contactName"
								name="contactName"
								initialValue={player?.contactName}
								validation={validateName}
								required="required"
							/>

							<Input
								divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-3"
								label="Teléfono de contacto de emergencia"
								placeholder="Teléfono de contacto de emergencia"
								id="contactPhoneNumber"
								name="contactPhoneNumber"
								type="text"
								initialValue={player?.contactPhoneNumber}
								validation={validateNumber}
								required="required"
							/>

							{(!player?.uuid || !player?.isComplete) && (
								<>
									<CheckboxInput
										id="terms"
										divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
										label="Acepto"
										name="terms"
										description="Términos y Condiciones (Responsiva y Liberación de Responsabilidad, Consentimiento fotografía y video, Uso de marcas registradas y Aviso de Privacidad)"
										linkTo={cup?.files?.termsAndConditionsDocUrl}
										required="required"
									/>

									<CheckboxInput
										id="rules"
										divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
										label="Acepto"
										name="rules"
										description={`Reglamento ${cup?.name || ''} ${CurrentYear}`}
										linkTo={cup?.files?.summonDocUrl}
										required="required"
									/>
								</>
							)}
						</div>
					</div>
					<div className="my-10 px-4 py-3 bg-gray-50 text-center sm:text-left sm:px-6">
						<button
							className={`btn-green position: relative ${
								player?.isComplete ? 'hidden' : ''
							}`}
							type="button"
							onClick={savePlayer}
						>
							Guardar
						</button>
						<button
							style={{ backgroundColor: getCupColor() }}
							className="mx-10 btn-pink mt-10 md:mt-0 lg:mt-0 position: relative"
							type="submit"
						>
							{player?.isComplete ? 'Modificar jugador' : 'Agregar Jugador'}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

const PlayerSizeSelector = ({ sizes, permalink, player }) => {
	console.log(player);
	let labelTalla =
		permalink === 'copa-giro-sur' ||
		permalink === 'copa-giro-poniente' ||
		permalink === 'copa-giro'
			? 'Niña'
			: 'Niño';
	return (
		<>
			<div className="my-5 col-span-3 sm:col-span-3 lg:col-span-2">
				<label htmlFor="sizeCategoryTop" className="block text-title">
					Talla Top:
				</label>
				<div>
					<RadioInput
						id="isChildTop"
						divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
						label={labelTalla}
						name="sizeCategoryTop"
						initialValue={player?.isChildTop ? 1 : 0}
					/>
					<RadioInput
						id="isAdultTop"
						divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
						label="Adulto"
						name="sizeCategoryTop"
						initialValue={player?.isChildTop ? 0 : 1}
					/>
				</div>
				<Input
					id="sizeTop"
					name="sizeTop"
					type="select"
					initialValue={player?.sizeTop ? player.sizeTop : 'XL'}
					options={sizes}
				/>
			</div>
			<div className="my-5 col-span-3 sm:col-span-3 lg:col-span-2">
				<label htmlFor="sizeCategoryBottom" className="block text-title">
					Talla Bottom:
				</label>
				<div>
					<RadioInput
						id="isChildBottom"
						divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
						label={labelTalla}
						name="sizeCategoryBottom"
						initialValue={player?.isChildBottom ? 1 : 0}
					/>
					<RadioInput
						id="isAdultBottom"
						divClassName="sm:my-5 col-span-6 sm:col-span-6 lg:col-span-6"
						label="Adulto"
						name="sizeCategoryBottom"
						initialValue={player?.isChildBottom ? 0 : 1}
					/>
				</div>
				<Input
					id="sizeBottom"
					name="sizeBottom"
					type="select"
					initialValue={player?.sizeBottom ? player.sizeBottom : 'XL'}
					options={sizes}
				/>
			</div>
		</>
	);
};

export default PlayerForm;
